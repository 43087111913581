import {StaticImage} from "gatsby-plugin-image";
import * as React from "react";

const Partner = () => (
                    <div className="container">
                        <h2>С нами сотрудничают</h2>
                        <div className={'row mb-69 part'}>
                            <StaticImage
                                src="../images/p-1.png"
                                width={149}
                                quality={95}
                                formats={["AUTO", "WEBP"]}
                                alt="Партнеры"
                                layout={'constrained'}
                                objectPosition={'center 60%'}
                                className="partner"
                                imgClassName={'img-partner'}
                            />
                            <StaticImage
                                src="../images/p-2.png"
                                width={250}
                                quality={95}
                                formats={["AUTO", "WEBP"]}
                                alt="Партнеры"
                                layout={'constrained'}
                                objectPosition={'center 60%'}
                                className="partner"
                                imgClassName={'img-partner'}
                            />
                            <StaticImage
                                src="../images/p-3.png"
                                width={156}
                                quality={95}
                                formats={["AUTO", "WEBP"]}
                                alt="Партнеры"
                                layout={'constrained'}
                                objectPosition={'center 60%'}
                                className="partner"
                                imgClassName={'img-partner'}
                            />
                            <StaticImage
                                src="../images/p-4.png"
                                width={149}
                                quality={95}
                                formats={["AUTO", "WEBP"]}
                                alt="Партнеры"
                                layout={'constrained'}
                                objectPosition={'center 60%'}
                                className="partner"
                                imgClassName={'img-partner'}
                            />
                            <StaticImage
                                src="../images/p-5.png"
                                width={267}
                                quality={95}
                                formats={["AUTO", "WEBP"]}
                                alt="Партнеры"
                                layout={'constrained'}
                                objectPosition={'center 60%'}
                                className="partner"
                                imgClassName={'img-partner'}
                            />
                            <StaticImage
                                src="../images/p-6.png"
                                width={170}
                                quality={95}
                                formats={["AUTO", "WEBP"]}
                                alt="Партнеры"
                                layout={'constrained'}
                                objectPosition={'center 60%'}
                                className="partner"
                                imgClassName={'img-partner'}
                            />
                            <StaticImage
                                src="../images/p-7.png"
                                width={134}
                                quality={95}
                                formats={["AUTO", "WEBP"]}
                                alt="Партнеры"
                                layout={'constrained'}
                                objectPosition={'center 60%'}
                                className="partner"
                                imgClassName={'img-partner'}
                            />
                        </div>
                    </div>
                    )

export default Partner