import {Link} from "gatsby"
import * as React from "react"
import {graphql, StaticQuery} from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import "./css/activity.css"

function Activity() {

    return (
        <div className="fluid" id={'activity'} >
            <div className="container">
                <h2>Наша деятельность</h2>
                <div className="row activity">


                    <StaticQuery
                        query={graphql`
                          {
                             allMdx (
                                    sort: {
                                      fields: [frontmatter___id]
                                      order: ASC
                                    }
                                    filter: {frontmatter: {category: {eq: "activity"}}}
                                  ){
                                edges {
                                  node {
                                    id
                                    frontmatter {
                                      id
                                      h1
                                      slug
                                      title
                                      description
                                      category
                                      image {
                                        childImageSharp {
                                          gatsbyImageData(
                                          width: 600
                                          formats: [AUTO, WEBP, AVIF]
                                          quality: 90
                                          )
                                        }
                                      }
                                      imageAlt
                                    }
                                  }
                                }
                              }
                          }
                        `}
                        render={({
                                     allMdx: {
                                         edges
                                     }
                                 }) => (
                            edges.map(({ node }) => (
                                <div className="col-3" key={node.frontmatter.id} >
                                    <div className={'activity-title'}>
                                        <GatsbyImage
                                            image  = {getImage(node.frontmatter.image.childImageSharp.gatsbyImageData)}
                                            alt = {node.frontmatter.imageAlt}
                                            loading ='lazy'
                                            className ='img-activity-main'
                                            imgClassName = 'img-activity-main'
                                        />
                                        <h3>{node.frontmatter.h1}</h3>
                                    </div>
                                    <p className={'activity-desc'}>{node.frontmatter.description}</p>
                                    <Link to={node.frontmatter.category + `/` + node.frontmatter.slug} className={'href-activity'}>
                                            Подробнее
                                            <svg width="33" height="16" viewBox="0 0 33 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M32.7071 8.70711C33.0976 8.31658 33.0976 7.68342 32.7071 7.29289L26.3431 0.928932C25.9526 0.538408 25.3195 0.538408 24.9289 0.928932C24.5384 1.31946 24.5384 1.95262 24.9289 2.34315L30.5858 8L24.9289 13.6569C24.5384 14.0474 24.5384 14.6805 24.9289 15.0711C25.3195 15.4616 25.9526 15.4616 26.3431 15.0711L32.7071 8.70711ZM0 9H32V7H0V9Z"
                                                    fill="#D90000"/>
                                            </svg>
                                    </Link>
                                </div>
                            ))
                        )}
                    />
                </div>
            </div>
        </div>
    )
}

export default Activity