import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import Flickity from "react-flickity-component"
import * as React from "react"
import {graphql, StaticQuery} from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import "flickity/css/flickity.css"
import "./css/projects.css"
import {useState, useEffect} from "react";

const flickityOptions = {
    initialIndex: 1,
    pageDots: false,
    wrapAround: true,
    imagesLoaded: true,
    prevNextButtons: true,
    cellAlign: 'left',
    draggable: true
}


const Projects = () => {
    const [YandexMap , setFrame] = useState('')

    useEffect(() => {
            setTimeout(() => {
                setFrame('https://yandex.ru/map-widget/v1/?um=constructor%3Ab0e6ec4e6d33b015a158ee9c03bbeb6a217c57f5f3944459110052f6d5eef55a&amp;source=constructor')},
                2000
        )
    })

    return (

        <div className="container" id={'projects'}>
            <div className="row">
                <div className="col-6">
                    <div className={'href-projects'}>
                        <h2>Наши проекты</h2>
                    </div>
                    <StaticImage
                        src="../images/dmitrovka.jpg"
                        width={600}
                        quality={95}
                        formats={["AUTO", "WEBP"]}
                        alt="ВДНХ"
                        layout={'constrained'}
                        objectPosition={'center 60%'}
                        style={{
                            width: '100%'
                        }}
                    />
                    <Link to={'/projects/reconstrukcia-dmitrovskoe-shosse/'} className={'vdnh-project'}>
                        <div>
                            Участок от транспортной развязки МКАД с Дмитровским шоссе и до границы города
                            <svg width="33" height="16" viewBox="0 0 33 16" xmlns="http://www.w3.org/2000/svg"
                                 fill="#B3B3B3" className={'ml-5'}>
                                <path
                                    d="M32.7071 8.70711C33.0976 8.31658 33.0976 7.68342 32.7071 7.29289L26.3431 0.928932C25.9526 0.538408 25.3195 0.538408 24.9289 0.928932C24.5384 1.31946 24.5384 1.95262 24.9289 2.34315L30.5858 8L24.9289 13.6569C24.5384 14.0474 24.5384 14.6805 24.9289 15.0711C25.3195 15.4616 25.9526 15.4616 26.3431 15.0711L32.7071 8.70711ZM0 9H32V7H0V9Z"/>
                            </svg>
                        </div>
                    </Link>
                </div>
                <div className="col-6">
                    <div className='map-button'>
                        <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.5 0.111206C11.6538 0.111206 15 3.5076 15 7.72382C15 9.53913 14.7115 10.8274 13.7308 12.4671C12.6923 14.1067 7.5 21.7779 7.5 21.7779C7.5 21.7779 2.30769 14.1067 1.26923 12.4671C0.288462 10.8274 0 9.53913 0 7.72382C0 3.5076 3.34615 0.111206 7.5 0.111206Z"
                                fill="#D90000"/>
                            <path
                                d="M7.50024 4.61157C9.18024 4.61157 10.5002 5.93157 10.5002 7.61157C10.5002 9.29157 9.18024 10.6116 7.50024 10.6116C5.82024 10.6116 4.50024 9.29157 4.50024 7.61157C4.50024 5.93157 5.82024 4.61157 7.50024 4.61157Z"
                                fill="white"/>
                        </svg>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="8" height="8" fill="#B3B3B3"/>
                            <rect x="10" width="8" height="8" fill="#B3B3B3"/>
                            <rect y="10" width="8" height="8" fill="#B3B3B3"/>
                            <rect x="10" y="10" width="8" height="8" fill="#B3B3B3"/>
                        </svg>
                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="6" width="14" height="2" fill="#B3B3B3"/>
                            <rect x="6" y="4" width="14" height="2" fill="#B3B3B3"/>
                            <rect x="6" y="16" width="14" height="2" fill="#B3B3B3"/>
                            <rect x="6" y="12" width="14" height="2" fill="#B3B3B3"/>
                            <rect x="6" y="8" width="14" height="2" fill="#B3B3B3"/>
                            <rect width="4" height="2" fill="#B3B3B3"/>
                            <rect y="4" width="4" height="2" fill="#B3B3B3"/>
                            <rect y="16" width="4" height="2" fill="#B3B3B3"/>
                            <rect y="12" width="4" height="2" fill="#B3B3B3"/>
                            <rect y="8" width="4" height="2" fill="#B3B3B3"/>
                        </svg>
                    </div>
                    <div id="map">
                        <iframe src={YandexMap} width="100%" height="555" frameBorder="0" loading="lazy" title='Yandex Map'></iframe>
                    </div>
                </div>
            </div>

            <Flickity
                className={'carousel projects'} // default ''
                data-flickity={''}
                elementType={'div'} // default 'div'
                options={flickityOptions} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
                static // default false
            >

                <StaticQuery
                    query={graphql`
                          {
                             allMdx (
                                    sort: {
                                      fields: [frontmatter___id]
                                      order: ASC
                                    }
                                    filter: {frontmatter: {category: {eq: "projects"}}}
                                  ){
                                edges {
                                  node {
                                    id
                                    frontmatter {
                                      id
                                      h1
                                      slug
                                      title
                                      description
                                      category
                                      image {
                                        childImageSharp {
                                          gatsbyImageData(
                                          width: 278
                                          formats: [AUTO, WEBP, AVIF]
                                          quality: 90
                                          )
                                        }
                                      }
                                      imageAlt
                                    }
                                  }
                                }
                              }
                          }
                        `}
                    render={({
                                 allMdx: {
                                     edges
                                 }
                             }) => (
                        edges.map(({node}) => (
                            <div className="carousel-cell" key={node.frontmatter.id}>
                                <div className={'projects-title'}>
                                    <GatsbyImage
                                        image={getImage(node.frontmatter.image.childImageSharp.gatsbyImageData)}
                                        alt={node.frontmatter.imageAlt}
                                        loading='lazy'
                                        className='img-carousel-projects'
                                        imgClassName='img-carousel-projects'
                                    />
                                    <h4 className={'carousel-projects'}>{node.frontmatter.h1}</h4>
                                </div>
                                <div className={'projects-desc'}>
                                    <p>{node.frontmatter.description}</p>
                                    <Link to={node.frontmatter.category + `/` + node.frontmatter.slug}
                                          className={'href-activity'}>
                                        Подробнее
                                        <svg width="33" height="16" viewBox="0 0 33 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M32.7071 8.70711C33.0976 8.31658 33.0976 7.68342 32.7071 7.29289L26.3431 0.928932C25.9526 0.538408 25.3195 0.538408 24.9289 0.928932C24.5384 1.31946 24.5384 1.95262 24.9289 2.34315L30.5858 8L24.9289 13.6569C24.5384 14.0474 24.5384 14.6805 24.9289 15.0711C25.3195 15.4616 25.9526 15.4616 26.3431 15.0711L32.7071 8.70711ZM0 9H32V7H0V9Z"
                                                fill="#D90000"/>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        ))
                    )}
                />

            </Flickity>
        </div>
    )
}

export default Projects