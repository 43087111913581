import * as React from "react"
import {StaticImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Projects from '../components/projects'
import Activity from '../components/activity'
import Partner from '../components/partner'
import {AnchorLink} from "gatsby-plugin-anchor-links";



const IndexPage = () => (
  <Layout>
    <SEO title="Горка Инжиниринг" description="Комплексное сопровождение объектов строительства"/>
    <StaticImage
      src="../images/2.jpg"
      width={1320}
      quality={95}
      formats={["AUTO", "WEBP"]}
      alt="Горка Инжиниринг"
      layout={'constrained'}
      objectPosition={'center top'}
      className ='img-baner-main'
      imgClassName = 'img-baner'
    />
    <div className="baner">
        <h1 className="header col-6">
            Комплексное сопровождение объектов строительства
        </h1>
        <div className="desk-baner col-6">
            <p>В 2021 году компания отпраздновала 10-летие с момента основания. 160+ специалистов, центральный офис в Москве, представительства в Санкт-Петербурге и Перми. Сотрудники ГОРКА Инжиниринг имеют необходимые сертификаты и регулярно проходят повышение квалификации. В нашем штате есть специалисты, имеющие научную степень, осуществляющие научную и преподавательскую деятельности.  Компания выполняет строительный контроль, выступает в роли генерального подрядчика или технического заказчика.</p>
        </div>
    </div>


        <Activity />



        <Projects />



        <Partner />

      <div className={'but-up'}>
          <AnchorLink to={'/#main'}>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-square-up" role="img"
                   xmlns="http://www.w3.org/2000/svg" width="36" height='36' viewBox="0 0 448 512">
                  <path fill="currentColor"
                        d="M0 432V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48zm207.029-281.941L71.515 285.573c-9.373 9.373-9.373 24.569 0 33.941l16.971 16.971c9.373 9.373 24.569 9.373 33.941 0L224 234.912l101.573 101.574c9.373 9.373 24.569 9.373 33.941 0l16.971-16.971c9.373-9.373 9.373-24.569 0-33.941L240.971 150.059c-9.373-9.373-24.569-9.373-33.942 0z"
                        className=""></path>
              </svg>
          </AnchorLink>
      </div>
  </Layout>
)

export default IndexPage
